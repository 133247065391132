exports.onClientEntry = () => {
  if (typeof window !== 'undefined') {
    // Reemplaza con tu propio ID del Píxel de Meta
    window.fbq = window.fbq || function () {
      (window.fbq.q = window.fbq.q || []).push(arguments);
    };
    window.fbq('init', process.env.META_PIXEL_ID); // Inserta tu ID de píxel de Meta aquí
    window.fbq('track', 'PageView');
  }
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== 'undefined') {
    window.fbq('track', 'PageView');
  }
};