exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-about-us-index-tsx": () => import("./../../../src/components/templates/aboutUs/index.tsx" /* webpackChunkName: "component---src-components-templates-about-us-index-tsx" */),
  "component---src-components-templates-cart-index-tsx": () => import("./../../../src/components/templates/cart/index.tsx" /* webpackChunkName: "component---src-components-templates-cart-index-tsx" */),
  "component---src-components-templates-frequent-questions-index-tsx": () => import("./../../../src/components/templates/frequentQuestions/index.tsx" /* webpackChunkName: "component---src-components-templates-frequent-questions-index-tsx" */),
  "component---src-components-templates-games-index-tsx": () => import("./../../../src/components/templates/games/index.tsx" /* webpackChunkName: "component---src-components-templates-games-index-tsx" */),
  "component---src-components-templates-games-portfolio-index-tsx": () => import("./../../../src/components/templates/gamesPortfolio/index.tsx" /* webpackChunkName: "component---src-components-templates-games-portfolio-index-tsx" */),
  "component---src-components-templates-login-index-tsx": () => import("./../../../src/components/templates/login/index.tsx" /* webpackChunkName: "component---src-components-templates-login-index-tsx" */),
  "component---src-components-templates-page-dynamic-index-tsx": () => import("./../../../src/components/templates/pageDynamic/index.tsx" /* webpackChunkName: "component---src-components-templates-page-dynamic-index-tsx" */),
  "component---src-components-templates-pending-transaction-index-tsx": () => import("./../../../src/components/templates/PendingTransaction/index.tsx" /* webpackChunkName: "component---src-components-templates-pending-transaction-index-tsx" */),
  "component---src-components-templates-pqrs-index-tsx": () => import("./../../../src/components/templates/pqrs/index.tsx" /* webpackChunkName: "component---src-components-templates-pqrs-index-tsx" */),
  "component---src-components-templates-recover-password-index-tsx": () => import("./../../../src/components/templates/recoverPassword/index.tsx" /* webpackChunkName: "component---src-components-templates-recover-password-index-tsx" */),
  "component---src-components-templates-register-contact-data-index-tsx": () => import("./../../../src/components/templates/registerContactData/index.tsx" /* webpackChunkName: "component---src-components-templates-register-contact-data-index-tsx" */),
  "component---src-components-templates-register-index-tsx": () => import("./../../../src/components/templates/register/index.tsx" /* webpackChunkName: "component---src-components-templates-register-index-tsx" */),
  "component---src-components-templates-register-personal-data-index-tsx": () => import("./../../../src/components/templates/registerPersonalData/index.tsx" /* webpackChunkName: "component---src-components-templates-register-personal-data-index-tsx" */),
  "component---src-components-templates-register-verification-index-tsx": () => import("./../../../src/components/templates/registerVerification/index.tsx" /* webpackChunkName: "component---src-components-templates-register-verification-index-tsx" */),
  "component---src-components-templates-rejected-transaction-index-tsx": () => import("./../../../src/components/templates/rejectedTransaction/index.tsx" /* webpackChunkName: "component---src-components-templates-rejected-transaction-index-tsx" */),
  "component---src-components-templates-resolutions-index-tsx": () => import("./../../../src/components/templates/resolutions/index.tsx" /* webpackChunkName: "component---src-components-templates-resolutions-index-tsx" */),
  "component---src-components-templates-shopping-history-detail-index-tsx": () => import("./../../../src/components/templates/shoppingHistoryDetail/index.tsx" /* webpackChunkName: "component---src-components-templates-shopping-history-detail-index-tsx" */),
  "component---src-components-templates-shopping-history-index-tsx": () => import("./../../../src/components/templates/shoppingHistory/index.tsx" /* webpackChunkName: "component---src-components-templates-shopping-history-index-tsx" */),
  "component---src-components-templates-simple-page-index-tsx": () => import("./../../../src/components/templates/simplePage/index.tsx" /* webpackChunkName: "component---src-components-templates-simple-page-index-tsx" */),
  "component---src-components-templates-terms-conditions-index-tsx": () => import("./../../../src/components/templates/termsConditions/index.tsx" /* webpackChunkName: "component---src-components-templates-terms-conditions-index-tsx" */),
  "component---src-components-templates-transaction-approved-index-tsx": () => import("./../../../src/components/templates/transactionApproved/index.tsx" /* webpackChunkName: "component---src-components-templates-transaction-approved-index-tsx" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-carrito-confirmacion-[token]-tsx": () => import("./../../../src/pages/carrito/confirmacion/[token].tsx" /* webpackChunkName: "component---src-pages-carrito-confirmacion-[token]-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-iniciar-sesion-cambiar-contrasena-[token]-tsx": () => import("./../../../src/pages/iniciar-sesion/cambiar-contrasena/[token].tsx" /* webpackChunkName: "component---src-pages-iniciar-sesion-cambiar-contrasena-[token]-tsx" */),
  "component---src-pages-lista-resultados-index-tsx": () => import("./../../../src/pages/lista-resultados/index.tsx" /* webpackChunkName: "component---src-pages-lista-resultados-index-tsx" */),
  "component---src-pages-registro-confirmado-index-tsx": () => import("./../../../src/pages/registro/confirmado/index.tsx" /* webpackChunkName: "component---src-pages-registro-confirmado-index-tsx" */),
  "component---src-pages-registro-confirmar-[token]-tsx": () => import("./../../../src/pages/registro/confirmar/[token].tsx" /* webpackChunkName: "component---src-pages-registro-confirmar-[token]-tsx" */)
}

